<script>
export default {
  metaInfo: {
    title: "Received SMS",
  }
};
</script>

<script setup>
import DynamicReceivedSmsView from '@/components/dynamic/ReceivedSmsView.vue';
</script>

<template>
  <DynamicReceivedSmsView/>
</template>

<style scoped>

</style>
